import { useEffect, useState } from 'react';
import { validateBet } from '@utils/helpers';

const Native = ({ value, disabled, setValue, min, max, afterDot }) => {
  const [inputValue, setInputValue] = useState(value.toFixed(afterDot));

  useEffect(() => {
    setInputValue(value.toFixed(afterDot));
  }, [value, afterDot]);

  const onInput = ({ target }) => {
    const inputValue = target.value;
    if (inputValue.includes('.') && inputValue.split('.')[1].length > 2) return;

    setInputValue(target.value);
  };

  const onBlur = () => {
    const validValue = validateBet({ value: inputValue, min, max });
    setInputValue(validValue.toFixed(afterDot));
    setValue(validValue);
  };

  const onWheel = (event) => {
    event.target.blur();
  };

  return (
    <input
      className="input"
      name="bet"
      type="number"
      disabled={disabled}
      value={inputValue}
      onWheel={onWheel}
      onChange={onInput}
      onBlur={onBlur}
    />
  );
};

export default Native;
