import { useContext, useLayoutEffect, useMemo } from 'react';
import { classNames } from '@utils/helpers';
import { appState } from '@utils/store.jsx';
import Typography from '@components/typography';
import { BetContext } from '../../../context/bet';
import { FreeBetContext } from '../../../context/free-bet';
import Suggestions from './parts/suggestions';
import styles from './index.module.css';

const BetInput = () => {
  const { afterDot } = appState.provider;
  const { betValue, setBetValue, disabled } = useContext(BetContext);

  const { suggestions } = useContext(FreeBetContext);

  const suggestion = useMemo(
    () => suggestions.find(({ betNominal }) => betNominal === betValue),
    [betValue, suggestions]
  );

  useLayoutEffect(() => {
    if (betValue) return;
    const { betNominal } = suggestions.find(({ total }) => total) || {};
    setBetValue(betNominal);
  }, [betValue, setBetValue, suggestions]);

  const containerClasses = classNames([styles.container, disabled && styles.disabled]);

  const { usage, total } = suggestion || { usage: 0, total: 0 };

  return (
    <div className={containerClasses}>
      <div className={styles.inputContainer}>
        <Typography variant="bold" size="16">
          {betValue?.toFixed(afterDot)}
        </Typography>

        <Typography variant="regular" size="14">
          {total - usage}
          <wbr />/{total}
        </Typography>
      </div>

      <Suggestions onClick={setBetValue} />
    </div>
  );
};

export default BetInput;
