export const isTouchDevice = 'ontouchstart' in window;

export const floor = (value) => Math.floor(value * 100) / 100;

const round = (value) => Math.round(value * 100) / 100;

export const validateBet = ({ value, min, max }) => {
  value = Number(value) || 0;
  if (!value) return min;
  if (value > max) return max;
  if (value < min) return min;
  return round(value);
};

export const classNames = (classNames) => classNames.filter(Boolean).join(' ');

export const isBetOutRange = (value, min, max) => {
  const number = Number(value) || 0;
  return number < min || number > max;
};

export const rangeRandom = (min, max) => Math.random() * (max - min) + min;

export const randomUUID = () =>
  crypto.randomUUID?.() ||
  '10000000-1000-4000-8000-100000000000'.replace(/[018]/g, (c) =>
    (+c ^ (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (+c / 4)))).toString(16)
  );

export const hideAddressBar = () => {
  const hide = () => requestAnimationFrame(() => window.scrollTo(0, 500));
  hide();

  window.addEventListener('orientationchange', hide);
  return () => {
    window.removeEventListener('orientationchange', hide);
  };
};

export const clamp = (min, value, max) => Math.min(Math.max(value, min), max);

export const timeoutPromise = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

const getAssetName = (asset) => asset.split('/').pop().split('.').shift();

export const formatAssets = (assets) =>
  Object.keys(assets).reduce((acc, key) => {
    acc[getAssetName(key)] = assets[key].default;
    return acc;
  }, {});
