import { getLocalize } from '@utils/locales';
import { appState } from '@utils/store';
import ButtonIcon from '@components/button-icon';
import Dropdown from '@components/dropdown';
import Icon from '@components/icon';
import Scroll from '@components/scroll';
import Typography from '@components/typography';
import FreeBet from './items/free-bets';
import Limits from './items/limits';
import MyBets from './items/my-bets';
import Profile from './items/profile';
import Rules from './items/rules';
import CheckItem from './parts/check-item';
import styles from './index.module.css';

const l = getLocalize('game.menu');

const Menu = () => {
  const { homePageUrl } = appState.provider;

  return (
    <Dropdown button={<ButtonIcon icon="Menu" />} closeButton={<ButtonIcon icon="Close" />}>
      <div className={styles.wrapper}>
        <Scroll>
          <div className={styles.container}>
            <div className={styles.profile}>
              <Profile />
            </div>

            <div className={styles.lightItems}>
              <CheckItem name="sound" label={l('sound')} icon="Sound" />
              <CheckItem name="music" label={l('music')} icon="Music" />
              <CheckItem name="animation" label={l('animation')} icon="Plane" />
              <CheckItem name="dark" label={l('dark')} icon="Moon" />
            </div>

            <div className={styles.items}>
              <FreeBet />
              <MyBets />
              <Rules />
              <Limits />
            </div>

            <a className={styles.home} href={homePageUrl} target="_blank">
              <Icon icon="Home" size="small" />
              <Typography variant="regular" size="12">
                {l('home')}
              </Typography>
            </a>
          </div>
        </Scroll>
      </div>
    </Dropdown>
  );
};

export default Menu;
