import { ACTIONS, CHANNELS } from '@utils/enums.js';
import { socket } from '@utils/socket.js';

export const syncBet = ({ betId, setAction, setBetValue, setAutoBet, setAutoCashOut, setIsFreeBet }) => {
  const SYNC_PLACE_BET = ({ betId: resBetId, betAmount, betNominal, maxMultiplier, type }) => {
    if (betId !== resBetId) return;

    setAutoBet(false);
    const isFreeBet = type === 'freebet';
    setIsFreeBet(isFreeBet);

    requestAnimationFrame(() => {
      setBetValue(isFreeBet ? betNominal : betAmount);
      if (maxMultiplier) setAutoCashOut(maxMultiplier);
      setAction(ACTIONS.CANCEL);
    });
  };

  socket.on(CHANNELS.SYNC_PLACE_BET, SYNC_PLACE_BET);

  return () => {
    socket.off(CHANNELS.SYNC_PLACE_BET, SYNC_PLACE_BET);
  };
};
