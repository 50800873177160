import { ACTIONS, BET_ERRORS, CHANNELS, ERRORS } from '@utils/enums';
import { getLocalize } from '@utils/locales';
import { socket } from '@utils/socket';
import { appResources, appState } from '@utils/store';

const l = getLocalize('game.bet.error');

export const buttonVariantsMap = {
  [ACTIONS.BET]: 'bet',
  [ACTIONS.CANCEL]: 'cancel',
  [ACTIONS.CASH_OUT]: 'cashOut',
  [ACTIONS.WAITING]: 'waiting',
  [ACTIONS.FREE]: 'freeBet',
};

const errorMap = {
  [ERRORS.FUND_WITHDRAW_FAILED]: () => l('balance'),
  [ERRORS.FREEBETS_EXPIRED]: () => l('freeBet'),
};

const skipErrors = [ERRORS.EXPECTED_BETTING_PHASE];

export const getPrevAction = (action) => (action === ACTIONS.BET ? ACTIONS.CANCEL : ACTIONS.BET);

export const playBetSound = (action) => {
  if (!appState.settings.sound) return;
  if (![ACTIONS.BET, ACTIONS.CANCEL].includes(action)) return;

  const audio = appResources.audios.bet;
  audio.customStart();
};

export const getNextAction = ({ action, autoBet }) => {
  if (autoBet && action === ACTIONS.CASH_OUT) return ACTIONS.CANCEL;
  return action === ACTIONS.BET ? ACTIONS.CANCEL : ACTIONS.BET;
};

// FIXME: remove listener inside waitBetPlace
const listenerCallback = {};
export const waitBetPlace = ({ betId, action }) =>
  new Promise((resolve) => {
    if (listenerCallback[betId]) {
      listenerCallback[betId]();
      delete listenerCallback[betId];
    }

    // Listen for success
    const channel = action === ACTIONS.BET ? CHANNELS.PLACE_BET_LOCAL : CHANNELS.CLEAR_BET_LOCAL;

    const BET_LOCAL = ({ betId: resBetId }) => {
      if (betId === resBetId) resolve(true);
    };
    socket.on(channel, BET_LOCAL);

    // Listen for errors
    const CLIENT_ERRORS = ({ error, betId: resBetId }) => {
      if (resBetId !== betId || !BET_ERRORS.includes(error)) return;

      if (BET_ERRORS.includes(error)) {
        resolve(false);
        if (!skipErrors.includes(error)) showError(error);
      }
    };
    socket.on(CHANNELS.CLIENT_ERRORS, CLIENT_ERRORS);

    listenerCallback[betId] = () => {
      socket.off(channel, BET_LOCAL);
      socket.off(CHANNELS.CLIENT_ERRORS, CLIENT_ERRORS);
    };
  });

export const listenAutoCashout = (callback) => {
  socket.on(CHANNELS.AUTO_CASH_OUT, callback);

  return () => {
    socket.off(CHANNELS.AUTO_CASH_OUT, callback);
  };
};

export const showError = (error) => {
  const message = errorMap[error]?.() || l('global');
  if (!message) return;

  appState.snackbar.open({ type: 'error', message });
};

// Send Bet Cancel or Cashout
const sendAction = (channel, params) => socket.emit(channel, { roundId: appState.roundId, ...params });

const onBetPlace = ({ betId }) => {
  const { betValue, freeBetValue, autoCashOut, type } = appState.bet[betId];
  const value = type === 'freebet' ? freeBetValue : betValue;
  const method = autoCashOut ? 'automatic' : 'manual';
  const maxMultiplier = autoCashOut || 0;
  const bet = { betId, method, maxMultiplier, betAmount: value, betNominal: value, type };
  // FIXME: make other request same params
  sendAction(CHANNELS.PLACE_BET, { bet });
};

const onBetClear = ({ betId }) => sendAction(CHANNELS.CLEAR_BET, { betId });

const onCashout = ({ betId }) => sendAction(CHANNELS.MANUAL_CASH_OUT, { betId });

export const sendBetMap = {
  [ACTIONS.BET]: onBetPlace,
  [ACTIONS.CANCEL]: onBetClear,
  [ACTIONS.CASH_OUT]: onCashout,
};
