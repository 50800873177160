import { useEffect, useState } from 'react';
import { CHANNELS } from '@utils/enums';
import { socket } from '@utils/socket';
import { appState } from '@utils/store';
import { getSuggestions } from './helpers';

const setGameSuggestions = (setSuggestions) => {
  socket.emit(CHANNELS.PLAYER_FREEBETS);

  let prevSuggestions = null;

  const PLAYER_FREEBETS = (res) => {
    const suggestions = getSuggestions(res, prevSuggestions);
    prevSuggestions = suggestions;
    setSuggestions(suggestions);
  };

  socket.on(CHANNELS.PLAYER_FREEBETS, PLAYER_FREEBETS);

  return () => {
    socket.off(CHANNELS.PLAYER_FREEBETS, PLAYER_FREEBETS);
  };
};

const useSuggestions = () => {
  const [suggestions, setSuggestions] = useState(appState.freeBet.suggestions || []);

  useEffect(() => setGameSuggestions(setSuggestions), []);

  useEffect(() => {
    appState.freeBet.suggestions = suggestions;
  }, [suggestions]);

  return suggestions;
};

export default useSuggestions;
