import { useContext } from 'react';
import { FreeBetContext } from '../../context/free-bet';
import FreeBet from '../free/check';
import AutoBet from './parts/auto/bet';
import AutoCashOut from './parts/auto/cashout';
import Button from './parts/button';
import styles from './index.module.css';

const BetButton = () => {
  const { showCheck } = useContext(FreeBetContext);

  return (
    <>
      <Button />

      <div className={styles.auto}>
        <AutoBet />
        {showCheck ? <FreeBet /> : <div />}
        <AutoCashOut />
      </div>
    </>
  );
};

export default BetButton;
