import { useEffect, useRef, useState } from 'react';
import { classNames } from '@utils/helpers';
import Keyboard from './keyboard';
import styles from './index.module.css';

const Touch = ({ label, suffix, min, max, disabled, value, setValue, afterDot }) => {
  const [inputValue, setInputValue] = useState(value.toFixed(afterDot));
  const keyboardRef = useRef();

  useEffect(() => {
    setInputValue(value.toFixed(afterDot));
  }, [value]);

  const onClick = async () => {
    if (disabled) return;
    const resValue = await keyboardRef.current.open(Number(inputValue));
    if (!resValue) return;
    setInputValue(resValue.toFixed(afterDot));
    setValue(resValue);
  };

  const inputClasses = classNames(['input', styles.input, disabled && styles.disabled]);

  const inputElement = (
    <div className={styles.inputContainer} onClick={onClick}>
      <input name="bet" className={inputClasses} disabled={disabled} value={inputValue} onChange={() => {}} />
    </div>
  );

  return (
    <>
      {inputElement}
      <Keyboard ref={keyboardRef} afterDot={afterDot} suffix={suffix} label={label} min={min} max={max} />
    </>
  );
};

export default Touch;
