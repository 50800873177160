import { useMemo } from 'react';
import { formatAssets } from '@utils/helpers.js';
import { getLocalize } from '@utils/locales';
import Accordion from '@components/accordion';
import Scroll from '@components/scroll';
import Typography from '@components/typography';
import Slider from '../slider';
import styles from './index.module.css';

const assets = formatAssets(import.meta.glob('/src/assets/images/rules/*.png', { eager: true }));

const l = getLocalize('game.rules');

const sliderItems = [
  { img: assets['1'], description: l('slider.1') },
  { img: assets['2'], description: l('slider.2') },
];

const Content = () => {
  const accordionItems = useMemo(
    () =>
      [...new Array(5)].map((_, index) => {
        const title = l(`accordion.${index}.title`);
        const description = l(`accordion.${index}.description`);

        return (
          <Accordion key={index} label={title}>
            <div className={styles.accordionItems}>
              {description.map((item, index) => (
                <Typography key={index} variant="regular" size="14">
                  ● {item}
                </Typography>
              ))}
            </div>
          </Accordion>
        );
      }),
    []
  );

  return (
    <Scroll>
      <div className={styles.container}>
        <div className={styles.how}>
          <div className={styles.text}>
            <Typography variant="bold" size="24">
              {l('how.title')}
            </Typography>
            <Typography variant="regular" size="14">
              {l('how.description')}
            </Typography>
          </div>

          <Slider items={sliderItems} />
        </div>

        <div className={styles.additional}>
          <div className={styles.text}>
            <Typography variant="bold" size="24">
              {l('additional.title')}
            </Typography>
          </div>

          <div className={styles.accordions}>{accordionItems}</div>
        </div>
      </div>
    </Scroll>
  );
};

export default Content;
