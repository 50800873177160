import { formatAssets } from '@utils/helpers.js';
import styles from './index.module.css';

const assets = formatAssets(import.meta.glob('/src/assets/images/clouds/*.svg', { eager: true }));

const Clouds = () => (
  <div className={styles.container}>
    <img name="bg_1" className={styles.background} src={assets['bg_1']} alt="cloud" />

    <img name="m_1" className={styles.middle} src={assets['m_1']} alt="cloud" />
    <img name="m_2" className={styles.middle} src={assets['m_2']} alt="cloud" />
    <img name="m_3" className={styles.middle} src={assets['m_3']} alt="cloud" />

    <img name="f_1" className={styles.front} src={assets['f_1']} alt="cloud" />
    <img name="f_2" className={styles.front} src={assets['f_2']} alt="cloud" />
  </div>
);

export default Clouds;
