import { classNames } from '@utils/helpers.js';
import Icon from '@components/icon';
import Typography from '@components/typography';
import styles from './index.module.css';

const keys = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '.', '0'];

const Keys = ({ onInput, hideDot }) => {
  const onClick = (event) => onInput(event);
  const numbers = keys.map((key) => (
    <button key={key} value={key} onClick={onClick}>
      <Typography variant="regular" size="20">
        {key}
      </Typography>
    </button>
  ));

  const className = classNames([styles.container, hideDot && styles.hideDot]);

  return (
    <div className={className}>
      {numbers}
      <button value="backspace" onClick={onClick}>
        <Icon variant="custom" icon="Backspace" />
      </button>
    </div>
  );
};

export default Keys;
