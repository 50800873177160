import { classNames } from '@utils/helpers';
import { getLocalize } from '@utils/locales';
import { appState } from '@utils/store';
import Typography from '@components/typography';
import asset from '@assets/images/snackbar/win.svg';
import Shine from './parts/shine';
import styles from './index.module.css';

const l = getLocalize('game.win.snackbar');

const WinSnackbar = ({ multiplier, win }) => {
  const { currency, afterDot } = appState.provider;

  return (
    <div className={styles.container}>
      <div className={styles.item}>
        <div className={styles.label}>
          <Typography variant="regular" size="14">
            {l('label')}
          </Typography>
        </div>
        <Typography variant="regular" size="16">
          {multiplier}x
        </Typography>
      </div>

      <img className={styles.image} src={asset} alt="win" width={43} height={24} />

      <div className={classNames([styles.item, styles.right])}>
        <Typography variant="bold" size="16">
          {l('win')} {win.toFixed(afterDot)} {currency}
        </Typography>
      </div>

      <div className={styles.shine}>
        <Shine />
      </div>
    </div>
  );
};

export default WinSnackbar;
