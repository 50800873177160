import { AnimatedSprite, Container } from '@pixi/react';
import { formatAssets } from '@utils/helpers.js';
import { getPlaneWidth } from '../../helpers';

const assets = formatAssets(import.meta.glob('/src/assets/images/plane/*.png', { eager: true }));

const darkImages = [assets['d_1'], assets['d_2']];
const lightImages = [assets['l_1'], assets['l_2']];

const Plane = ({ coordinates, dark }) => {
  const { x } = coordinates;

  const planeWidth = getPlaneWidth();

  if (x === -planeWidth) return null;

  const images = dark ? darkImages : lightImages;

  return (
    <Container position={coordinates} scale={planeWidth / 200}>
      <AnimatedSprite isPlaying images={images} animationSpeed={0.35} anchor={{ x: 0.01, y: 0.82 }} />
    </Container>
  );
};

export default Plane;
