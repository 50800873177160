import { useContext, useEffect, useState, useCallback, useRef } from 'react';
import { CHANNELS, PHASES } from '@utils/enums';
import { socket } from '@utils/socket';
import { appState } from '@utils/store';
import { PhaseContext } from '@context/phase';

const defaultLimit = 10;

const emit = (skip = 0, limit = defaultLimit) => socket.emit(CHANNELS.PLAYER_BETS, { skip, limit });

const setMyBets = ({ setItems, totalRef, setLoading }) => {
  const PLAYER_BETS = ({ docs, total, skip }) =>
    setItems((prev) => {
      totalRef.current = total;
      setLoading(false);

      const bets = !skip ? [...docs, ...prev] : [...prev, ...docs];
      return bets.filter((value, index, self) => index === self.findIndex(({ _id }) => _id === value._id));
    });

  socket.on(CHANNELS.PLAYER_BETS, PLAYER_BETS);

  return () => {
    socket.off(CHANNELS.PLAYER_BETS, PLAYER_BETS);
  };
};

const useMyBets = () => {
  const saved = appState.myBets;
  const [items, setItems] = useState(saved.items || []);
  const [loading, setLoading] = useState(true);

  const totalRef = useRef(saved.total || 0);

  const phase = useContext(PhaseContext);

  useEffect(() => {
    if (!items.length) emit();
    else setLoading(false);
  }, [items.length]);

  useEffect(() => {
    appState.myBets = { items, total: totalRef.current };
  }, [items]);

  useEffect(() => setMyBets({ setItems, totalRef, setLoading }), []);

  useEffect(() => {
    if (phase === PHASES.BETTING) emit(0, 2);
  }, [phase]);

  const onLoadMore = useCallback(() => {
    setLoading(true);
    emit(items.length, defaultLimit);
  }, [items.length]);

  const loadMore = items.length < totalRef.current ? onLoadMore : null;
  const bets = !items.length && loading ? null : items;

  return { bets, loading, loadMore };
};

export default useMyBets;
