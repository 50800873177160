import { useContext } from 'react';
import { localize } from '@utils/locales';
import Toggle from '@components/toggle';
import Typography from '@components/typography';
import { BetContext } from '../../../../../context/bet';
import styles from './index.module.css';

const Bet = () => {
  const { autoBet, setAutoBet } = useContext(BetContext);

  const onChange = (checked) => {
    setAutoBet(checked);
  };

  return (
    <div className={styles.container}>
      <span className={styles.label}>
        <Typography variant="regular" size="12">
          {localize('game.bet.autoBet')}
        </Typography>
      </span>
      <Toggle checked={autoBet} onChange={onChange} />
    </div>
  );
};

export default Bet;
