import { localize } from '@utils/locales';
import { appState } from '@utils/store';
import Icon from '@components/icon';
import Typography from '@components/typography';
import Details from '@game/parts/history/parts/details';
import LChip from '../../../../components/chip';
import LItem from '../../../../components/item';
import { formatDate } from './helpers';
import styles from './index.module.css';

const Item = ({ roundId, createdAt, betAmount, win, multiplier }) => {
  const { afterDot } = appState.provider;

  const onClick = () => {
    if (roundId === appState.roundId) return;

    const element = <Details roundId={roundId} />;
    appState.modal.open({ element, label: localize('game.history.details.title') });
  };

  return (
    <LItem onClick={onClick} isSuccess={!!win}>
      <Typography variant="regular" size="14">
        {formatDate(createdAt)}
      </Typography>

      <div className={styles.bet}>
        <Typography variant="regular" size="14">
          {betAmount.toFixed(afterDot)}
        </Typography>

        {win && <LChip>{multiplier.toFixed(2)}x</LChip>}
      </div>

      <div className={styles.win}>
        <Typography variant="regular" size="14">
          {win && win.toFixed(afterDot)}
        </Typography>

        <Icon icon="Shield" variant="success" disabled={roundId === appState.roundId} />
      </div>
    </LItem>
  );
};

export default Item;
