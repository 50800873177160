import { createRoot } from 'react-dom/client';
import 'core-js/stable';
import 'intersection-observer';
import ErrorBoundary from '@components/error/boundary';
import Modal from '@components/modal';
import Snackbars from '@components/snackbars';
import App from './App';
import './index.css';

const rootElement = document.getElementById('root');

createRoot(rootElement).render(
  <ErrorBoundary>
    <App />
    <Snackbars />
    <Modal />
  </ErrorBoundary>
);
