import { useContext, useEffect, useMemo } from 'react';
import { PhaseContext } from '@context/phase';
import { playSound, getSounds } from './helpers';

const Sound = () => {
  const phase = useContext(PhaseContext);

  const sounds = useMemo(() => getSounds(), []);

  useEffect(() => {
    playSound(sounds.background, 0, true);
  }, [sounds]);

  useEffect(() => {
    const sound = sounds[phase];
    if (sound) playSound(sound);
  }, [sounds, phase]);

  return null;
};

export default Sound;
