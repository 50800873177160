import { memo } from 'react';
import { Container, Stage } from '@pixi/react';
import useResolution from '@hooks/useResolution';
import Stars from './parts/stars';

const stageOptions = {
  antialias: false,
  resolution: 1,
  interactiveChildren: false,
  backgroundAlpha: 0,
};

const Dark = ({ animate }) => {
  const resolution = useResolution();

  if (!resolution) return null;

  const { width, height } = resolution;

  return (
    <Stage options={stageOptions} width={width} height={height}>
      <Container position={{ x: width, y: height }}>
        <Stars animate={animate} resolution={resolution} size={{ min: 1.5, max: 2 }} />
        <Stars animate={animate} resolution={resolution} size={{ min: 1, max: 1.5 }} speed={0.5} />
      </Container>
    </Stage>
  );
};

export default memo(Dark);
