import { useEffect, useState } from 'react';
import { getLocalize, localize } from '@utils/locales';
import { socket } from '@utils/socket';
import { appState } from '@utils/store';
import Copy from '@components/copy';
import IconContainer from '@components/icon-container';
import ListItem from '@components/list-item';
import Scroll from '@components/scroll';
import ModalSkeleton from '@components/skeleton';
import Typography from '@components/typography';
import ProvablyFair from '@game/parts/provably-fair';
import Combined from './parts/combined';
import { clientHash } from './helpers';
import styles from './index.module.css';

const l = getLocalize('game.history.details');

const getDetails = (roundId, setDetails) => {
  socket.emit('ROUND_DETAILS', { roundId });

  const ON_ROUND_DETAILS = ({ details }) => {
    details.date = new Date(details.createdAt).toLocaleTimeString([], { timeStyle: 'short' });
    setDetails(details);
  };

  socket.on('ROUND_DETAILS', ON_ROUND_DETAILS);

  return () => {
    socket.off('ROUND_DETAILS', ON_ROUND_DETAILS);
  };
};

const Details = ({ roundId, dark }) => {
  const [details, setDetails] = useState(null);

  useEffect(() => {
    setDetails(null);
    return getDetails(roundId, setDetails);
  }, [roundId]);

  if (!details) return <ModalSkeleton />;

  const onProvablyFair = () => {
    const element = <ProvablyFair dark={dark} />;
    appState.modal.open({ element, label: localize('game.provablyFair.title') });
  };

  return (
    <Scroll>
      <div className={styles.container}>
        <div className={styles.header}>
          <div className={styles.top}>
            <IconContainer variant="danger" icon="PlaneFilled" size="large" />
            <Typography variant="bold" size="32">
              {details.multiplierCrash}x
            </Typography>
          </div>

          <div className={styles.bottom}>
            <Typography variant="regular" size="14">
              {l('date')}: {details.date}
            </Typography>
            <div className={styles.roundId}>
              <span className={styles.ellipsis}>
                <Typography variant="regular" size="14">
                  {l('round')}: {details._id}
                </Typography>
              </span>
              <Copy value={details._id} />
            </div>
          </div>
        </div>

        <div className={styles.item}>
          <ListItem
            variant="ghost"
            prefix={<IconContainer variant="secondary" icon="Server" size="medium" />}
            label={l('server.label')}
            description={l('server.description')}
          />

          <div className={styles.itemCopy}>
            <div className={styles.ellipsis}>
              <Typography variant="regular" size="14">
                {details.serverHash}
              </Typography>
            </div>

            <Copy value={details.serverHash} />
          </div>
        </div>

        {clientHash({ details, l })}

        <div className={styles.item}>
          <ListItem
            variant="ghost"
            prefix={<IconContainer variant="secondary" icon="ServerPrivacy" size="medium" />}
            label={l('combined.label')}
            description={l('combined.description')}
          />

          <div className={styles.itemCopy}>
            <div className={styles.ellipsis}>
              <Typography variant="regular" size="14">
                {details.roundHash}
              </Typography>
            </div>
            <Copy value={details.roundHash} />
          </div>
        </div>

        <Combined {...details} />

        <div className={styles.provablyFair} onClick={onProvablyFair}>
          <Typography variant="bold" size="16" underline>
            {l('provablyFair')}
          </Typography>
        </div>
      </div>
    </Scroll>
  );
};

export default Details;
