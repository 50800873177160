import { appState } from '@utils/store';
import LChip from '../../../../components/chip';
import styles from './index.module.css';

const Suggestions = ({ onClick }) => {
  const { betSuggestions } = appState.provider;

  const buttons = betSuggestions.map((bet) => (
    <LChip key={bet} onClick={() => onClick(bet)}>
      {bet}
    </LChip>
  ));

  return <div className={styles.container}>{buttons}</div>;
};

export default Suggestions;
