import { setLocalize } from '@utils/locales';
import audioPreload from './parts/audios';
import imagesPreload from './parts/images';

const preload = () => {
  const promises = [setLocalize(), imagesPreload(), audioPreload()];
  return Promise.all(promises);
};

export default preload;
