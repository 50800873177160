import { getLocalize } from '@utils/locales';
import Chip from '@components/chip';
import Icon from '@components/icon';
import Typography from '@components/typography';
import LChip from '../../../../components/chip';
import Table from '../table';
import { usePreviousBets } from './parts/usePreviousBets';
import styles from './index.module.css';

const l = getLocalize('game.placedBets.round.previous');

const Previous = ({ setTab }) => {
  const { bets, multiplier } = usePreviousBets();

  return (
    <div className={styles.container}>
      <div className={styles.total}>
        <Typography variant="bold" size="12">
          {l('total')}
          {bets?.length ? `: ${bets.length}` : ''}
        </Typography>

        {multiplier && <LChip>{multiplier.toFixed(2)}x</LChip>}

        <Chip
          onClick={() => setTab('current')}
          prefix={<Icon icon="ClockRewind" size="xSmall" />}
          suffix={<Icon icon="Close" size="xSmall" />}
        >
          {l('previous')}
        </Chip>
      </div>
      <Table items={bets} />
    </div>
  );
};

export default Previous;
