import { useContext } from 'react';
import BetProvider from './context/bet';
import FreeBetProvider, { FreeBetContext } from './context/free-bet';
import BetButton from './parts/bet-button';
import BetInput from './parts/bet-input';
import FreeBetInput from './parts/free/bet-input';
import styles from './index.module.css';

const Bet = () => {
  const { isFreeBet } = useContext(FreeBetContext);

  const InputElement = isFreeBet ? FreeBetInput : BetInput;

  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        <InputElement />
        <BetButton />
      </div>
    </div>
  );
};

const BetWrapper = ({ betId }) => (
  <FreeBetProvider betId={betId}>
    <BetProvider betId={betId}>
      <Bet />
    </BetProvider>
  </FreeBetProvider>
);

export default BetWrapper;
