import { useCallback, useEffect, useState } from 'react';
import { randomUUID } from '@utils/helpers';
import { appState } from '@utils/store';
import Snackbar from './parts/snackbar';
import styles from './index.module.css';

const Snackbars = () => {
  const [items, setItems] = useState([]);

  const open = useCallback((item) => {
    setItems((prev) => [{ id: randomUUID(), ...item }, ...prev]);
    setTimeout(() => setItems((prev) => prev.slice(0, -1)), 3000);
  }, []);

  useEffect(() => {
    appState.snackbar = { open };
  }, [open]);

  if (!items.length) return null;

  return (
    <div className={styles.wrapper}>
      <div className={styles.snackbars}>
        {items.slice(0, 3).map(({ id, type, message, element }) => (
          <Snackbar key={id} type={type} message={message}>
            {element}
          </Snackbar>
        ))}
      </div>
    </div>
  );
};

export default Snackbars;
