import { getLocalize } from '@utils/locales';
import { appState } from '@utils/store';
import Typography from '@components/typography';
import styles from './index.module.css';

const l = getLocalize('game.limits');

const Content = () => {
  const { maxBet, minBet, currency, maxWin } = appState.provider;

  const items = [
    { label: l('min'), value: `${minBet} ${currency}` },
    { label: l('max'), value: `${maxBet} ${currency}` },
    { label: l('win'), value: `${maxWin} ${currency}` },
  ];

  return (
    <div className={styles.container}>
      {items.map(({ label, value }) => (
        <div key={label} className={styles.item}>
          <Typography variant="bold" size="14">
            {label}
          </Typography>
          <Typography variant="bold" size="14">
            {value}
          </Typography>
        </div>
      ))}
    </div>
  );
};

export default Content;
