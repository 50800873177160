import { formatAssets } from '@utils/helpers.js';
import { getLocalize } from '@utils/locales';
import IconContainer from '@components/icon-container';
import ListItem from '@components/list-item';
import Scroll from '@components/scroll';
import Typography from '@components/typography';
import styles from './index.module.css';

const assets = formatAssets(import.meta.glob('/src/assets/images/provably-fair/*.png', { eager: true }));

const l = getLocalize('game.provablyFair');

const ProvablyFair = ({ dark }) => {
  return (
    <Scroll>
      <div className={styles.container}>
        <div className={styles.what}>
          <ListItem
            variant="ghost"
            prefix={<IconContainer variant="success" icon="Shield" />}
            label={l('what.title')}
          />

          <div className={styles.divider} />

          <Typography variant="regular" size="14">
            <span className={styles.description}>{l('what.description')}</span>
          </Typography>
        </div>

        <div className={styles.how}>
          <Typography variant="bold" size="24">
            {l('how.title')}
          </Typography>

          <div className={styles.item}>
            <Typography variant="regular" size="16">
              {l('how.short.title')}
            </Typography>

            <Typography variant="regular" size="14">
              <span className={styles.description}>{l('how.short.description')}</span>
            </Typography>
          </div>

          <div className={styles.item}>
            <Typography variant="regular" size="16">
              {l('how.long.title')}
            </Typography>

            <Typography variant="regular" size="14">
              <span className={styles.description}>{l('how.long.description')}</span>
            </Typography>
          </div>

          <div className={styles.image}>
            <img src={assets[dark ? 'dark' : 'light']} alt="Provably Fair" />
          </div>
        </div>

        <div className={styles.reliability}>
          <Typography variant="bold" size="24">
            {l('reliability.title')}
          </Typography>

          <div className={styles.item}>
            <div className={styles.description}>
              {l('reliability.description').map((description, index) => (
                <Typography key={index} variant="regular" size="14">
                  ● {description}
                </Typography>
              ))}
            </div>
          </div>
        </div>
      </div>
    </Scroll>
  );
};

export default ProvablyFair;
