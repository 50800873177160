import { useCallback, useEffect, useRef, useState } from 'react';
import { classNames, randomUUID } from '@utils/helpers';
import { appState } from '@utils/store';
import ButtonIcon from '@components/button-icon';
import Typography from '@components/typography';
import styles from './index.module.css';

const Modal = () => {
  const [layers, setLayers] = useState([]);
  const wrapperRef = useRef(null);

  // force, {label, element, unclosable, short}
  const open = useCallback(({ force, ...props }) => {
    if (force) setLayers([props]);
    else setLayers((prev) => [...prev, props]);
  }, []);

  const onBack = useCallback((event) => {
    event.stopPropagation();
    setLayers((prev) => prev.slice(0, -1));
  }, []);

  const onClose = useCallback(() => {
    if (!layers.length) return;

    const { classList } = wrapperRef.current;
    if (!classList) return;

    classList.add(styles.hide);
    setTimeout(() => {
      setLayers([]);
      classList?.remove(styles.hide);
    }, 100);
  }, [layers]);

  useEffect(() => {
    appState.modal = { open, close: onClose };
  }, [open, onClose]);

  useEffect(() => {
    const onKeydown = (event) => {
      if (event.key === 'Escape') {
        if (layers.at(-1)?.unclosable) return;
        onClose();
      }
    };
    window.addEventListener('keydown', onKeydown);

    return () => window.removeEventListener('keydown', onKeydown);
  }, [layers, onClose]);

  const onWrapperClick = () => {
    if (layers.at(-1)?.unclosable) return;
    onClose();
  };

  if (!layers.length) return null;

  const { label, element, unclosable, short } = layers.at(-1);

  const className = classNames([styles.wrapper, short && styles.short, unclosable && styles.unclosable]);

  const headerRender = (
    <div className={styles.header}>
      {layers.length > 1 && <ButtonIcon size="large" icon="ChevronLeft" onClick={onBack} />}
      {label && (
        <Typography variant="bold" size="24">
          {label}
        </Typography>
      )}
      {!unclosable && (
        <div className={styles.back}>
          <ButtonIcon size="large" icon="Close" onClick={onClose} />
        </div>
      )}
    </div>
  );

  return (
    <div key={randomUUID()} ref={wrapperRef} className={className} onClick={onWrapperClick}>
      <div className={styles.container} onClick={(event) => event.stopPropagation()}>
        <div className={styles.modal}>
          {headerRender}

          {element}
        </div>
      </div>
    </div>
  );
};

export default Modal;
