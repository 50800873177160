import { useContext } from 'react';
import { SettingsContext } from '@context/settings';
import Dark from './parts/dark';
import Light from './parts/light';
import styles from './index.module.css';

const Space = () => {
  const { settings } = useContext(SettingsContext);

  const { dark, animation } = settings;

  return <div className={styles.wrapper}>{dark ? <Dark animate={animation} /> : <Light animate={animation} />}</div>;
};

export default Space;
