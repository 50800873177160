import { createContext, useEffect, useMemo, useState, useContext, useLayoutEffect } from 'react';
import { ACTIONS } from '@utils/enums';
import { appState } from '@utils/store';
import usePrevious from '@hooks/usePrevious';
import { FreeBetContext } from '../free-bet';
import { syncBet } from './helper.js';

export const BetContext = createContext(null);

const BetProvider = ({ betId, children }) => {
  const betState = useMemo(() => appState.bet[betId], [betId]);

  const { isFreeBet, setIsFreeBet } = useContext(FreeBetContext);
  const prevIsFreeBet = usePrevious(isFreeBet);
  const freeBetChanged = !(prevIsFreeBet === isFreeBet);

  const [action, setAction] = useState(betState.action || ACTIONS.BET);
  const [betValue, setBetValue] = useState(isFreeBet ? betState.freeBetValue : betState.betValue || 0);
  const [autoBet, setAutoBet] = useState(betState.autoBet || false);
  const [autoCashOut, setAutoCashOut] = useState(betState.autoCashOut || 0);
  const [loading, setLoading] = useState(betState.loading || false);

  const disabled = action !== ACTIONS.BET || loading;

  useLayoutEffect(() => {
    if (freeBetChanged) setAction(ACTIONS.BET);
  }, [freeBetChanged]);

  useLayoutEffect(() => {
    if (freeBetChanged) setAutoBet(false);
  }, [freeBetChanged]);

  useLayoutEffect(() => {
    if (freeBetChanged) setAutoCashOut(false);
  }, [freeBetChanged]);

  useLayoutEffect(() => {
    if (!freeBetChanged) return;
    if (isFreeBet) setBetValue(betState.freeBetValue);
    else setBetValue(betState.betValue);
  }, [isFreeBet, freeBetChanged, betState]);

  useEffect(
    () => syncBet({ betId, setAction, setBetValue, setAutoBet, setAutoCashOut, setIsFreeBet }),
    [betId, setIsFreeBet]
  );

  useEffect(() => {
    betState.action = action;
  }, [betState, action]);

  useEffect(() => {
    betState.autoBet = autoBet;
  }, [betState, autoBet]);

  useEffect(() => {
    betState.autoCashOut = autoCashOut;
  }, [betState, autoCashOut]);

  useEffect(() => {
    betState.loading = loading;
  }, [betState, loading]);

  useEffect(() => {
    if (isFreeBet) betState.freeBetValue = betValue;
    else betState.betValue = betValue;
  }, [betState, betValue, isFreeBet, autoCashOut]);

  const value = {
    betId,
    action,
    betValue,
    setBetValue,
    setAction,
    loading,
    setLoading,
    autoBet,
    setAutoBet,
    autoCashOut,
    setAutoCashOut,
    disabled,
  };

  return <BetContext.Provider value={value}>{children}</BetContext.Provider>;
};

export default BetProvider;
