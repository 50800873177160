import { classNames, formatAssets } from '@utils/helpers';
import styles from './index.module.css';

const assets = formatAssets(import.meta.glob('/src/assets/images/avatars/*.jpg', { eager: true }));

const Avatar = ({ name, size = 'medium', active, onClick }) => {
  const className = classNames([styles.container, active && styles.active, styles[size], onClick && styles.clickable]);

  return (
    <div className={className} onClick={() => onClick?.()}>
      <img className={styles.image} src={assets[name]} alt={name} />
    </div>
  );
};

export default Avatar;
