import { useEffect, useState } from 'react';
import { classNames } from '@utils/helpers';
import FullScreen from '@components/full-screen';
import Balance from '@game/parts/balance';
import Menu from '@game/parts/menu';
import asset from '@assets/images/logo.svg';
import styles from './index.module.css';

const Header = ({ sticky, bottomRef }) => {
  const [colored, setColored] = useState(false);

  useEffect(() => {
    const bottom = bottomRef?.current;
    if (!bottom || !sticky) return;

    const observer = new IntersectionObserver((entries) => {
      const { isIntersecting } = entries[0];
      setColored(isIntersecting);
    });

    observer.observe(bottom);

    return () => observer.disconnect();
  }, [sticky, bottomRef]);

  const className = classNames([styles.container, sticky && styles.sticky, colored && styles.colored]);

  return (
    <header className={className}>
      <img className={styles.image} src={asset} alt="logo" width={94} height={25} />

      <div className={styles.right}>
        <Balance />
        <FullScreen />
        <Menu />
      </div>
    </header>
  );
};

export default Header;
