import { getLocalize } from '@utils/locales';
import { appState } from '@utils/store';
import Button from '@components/button';
import Icon from '@components/icon';
import Typography from '@components/typography';
import styles from './index.module.css';

const l = getLocalize('component.error.connectFailed');

const ConnectFailed = () => {
  const { homePageUrl } = appState.provider;

  return (
    <div className={styles.container}>
      <div className={styles.icon}>
        <Icon variant="destructive" icon="Wifi" size="large" />
      </div>

      <div className={styles.text}>
        <Typography variant="bold" size="24">
          {l('title')}
        </Typography>

        <Typography variant="regular" size="18">
          {l('description')}
        </Typography>
      </div>

      <a className={styles.button} href={homePageUrl} target="_blank">
        <Button variant="neutral">{l('home')}</Button>
      </a>
    </div>
  );
};

export default ConnectFailed;
