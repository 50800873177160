import { CHANNELS, PHASES } from '@utils/enums';
import { socket } from '@utils/socket';
import { appState, appResources } from '@utils/store';
import WinSnackbar from '@components/snackbars/parts/win-snackbar';

const playWinSound = () => {
  if (!appState.settings.sound) return;
  const audio = appResources.audios.win;
  audio.customStart();
};

export const onPlayerWin = (callback) => {
  const ROUND_CASHOUTS = ({ playerId, ...rest }) => {
    const userId = appState.session.playerId;
    if (playerId === userId) callback({ playerId, ...rest });
  };

  socket.on(CHANNELS.ROUND_CASHOUTS, ROUND_CASHOUTS);

  return () => {
    socket.off(CHANNELS.ROUND_CASHOUTS, ROUND_CASHOUTS);
  };
};

export const onWin = () => {
  const callback = ({ multiplier, win }) => {
    playWinSound();

    const element = <WinSnackbar multiplier={multiplier} win={win} />;
    appState.snackbar.open({ type: 'success', element });
  };

  return onPlayerWin(callback);
};

export const listenSocketError = (callback) => {
  socket.on(CHANNELS.CLIENT_ERRORS, callback);

  return () => {
    socket.off(CHANNELS.CLIENT_ERRORS, callback);
  };
};

export const joinNextRound = () => {
  const GAME_UPDATE = ({ phase, nextRoundId }) => {
    if (phase !== PHASES.GAME_ENDED) return;
    appState.roundId = nextRoundId;
    socket.emit(CHANNELS.JOIN_ROUND, { roundId: nextRoundId });
  };

  socket.on(CHANNELS.GAME_UPDATE, GAME_UPDATE);

  return () => {
    socket.off(CHANNELS.GAME_UPDATE, GAME_UPDATE);
  };
};
